import React from "react"

const Footer = () => {
  return (
    <>
      <footer>
        <div className='conatiner text-center top'>
          <div className='img'>
           
          </div>
          <div>

          </div>
          <p><b>© 2023 Realto Solutions- All rights Reserved</b></p>
        </div>
      </footer>
    </>
  )
}

export default Footer
