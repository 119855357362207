import React from 'react'
import Header from './Component/Head/Header'
import './App.css'
import Home from './Component/hero/Home'
import Feat from './Component/Feat/Feat'
import Portfolio from './Component/Porfolio/Portfolio'
import Resume from './Component/Resume/Resume'
import Testimonial from './Component/Testimonial/Testimonial'
import Contact from './Component/Contact/Contact'
import Footer from './Component/Footer'


const App = () => 
{
  return(
    <>
        <Header />
        <Home/>
        <Portfolio/>
        <Resume/>
         <Feat/>
         <Testimonial/>
        <Contact/>
        <Footer />

       
  </>
  )
}

export default App
