const ResumeApi = [
    {
      id: 1,
      category: "education",
      year: "Trends to deliver innovative solutions.",
      title: "Website Development",
      desc: "From front-end development using HTML, CSS, and JavaScript, to back-end development with PHP, Python, or Node.js, we have the expertise to leverage the right tools for your project.",
      
    },
    {
      id: 2,
      category: "education",
      year: "Services to customers over the internet. ",
      title: "E-Commerce Website",
      desc: " It serves as a virtual storefront, allowing customers to browse products, add items to their cart, and complete purchases online.User accounts enhance the convenience shopping experience.",
      
    },
    {
      id: 3,
      category: "education",
      year: " providing high-quality software products ",
      title: "Software Development",
      desc: "Software development agencies, such as AIS Software, software packages to suit the specific needs of your business, making life easier and better for you and your employees.",
      
    },
    {
      id: 4,
      category: "experience",
      year: "AI is transforming software development. ",
      title: "AI Application",
      desc: "AI algorithms can improve project planning, with automation and enhance user experience. A recent report found that AI-enhanced the increased the productivity of a developer.",
      
    },
    {
      id: 5,
      category: "experience",
      year: "(UI) and User Experience (UX) Optimization",
      title: "App Development",
      desc: "companies focus on optimizing the UI and UX of mobile applications to ensure a seamless and intuitive user experience. They design and create content that aligns with the overall app design.",
      
    },
    {
      id: 6,
      category: "experience",
      year: "our expert website development solutions",
      title: "Digital Marketing",
      desc: "A digital marketing content website development company specializes in creating and optimizing websites that are designed to effectively promote products, services, or brands online.",
      
    },
    
  ]
  
  export default ResumeApi