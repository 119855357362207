const Portfolio_data = [
    {
      id: 1,
      category: " WEBSITE DEVELOPMENT",
      totalLike: "",
      title: "Creating impactful online  code and design.",
      image: "./image/pic1.png",
    },
    {
      id: 2,
      category: "SOFTWARE DEVELOPMENT",
      totalLike: "",
      title: " Creating software applications to user needs.",
      image: "./image/pic4.png",
    },
    {
      id: 3,
      category: "APP DEVELOPMENT",
      totalLike: "",
      title: "Mobile app landing design & maintain. ",
      image: "./image/pic10.png",
    },
    {
      id: 4,
      category: "Networks infra &  Monitoring",
      totalLike: "",
      title: "Designing networks and implementing",
      image: "./image/pic7.png",
    },
    {
      id: 5,
      category: "Wifi Services",
      totalLike: "",
      title: "We are developing next-level Services.",
      image: "./image/pic8.png",
    },
    {
      id: 6,
      category: "SD WAN & WAN",
      totalLike: "",
      title: "SD WAN, the Next Generation Secure.",
      image: "./image/pic9.png",
    },
  ]
  export default Portfolio_data