const data=[
    {
        id:1, 
        image:"https://img.icons8.com/ios/50/web-design.png",
        title:"Web Development",
        desc:"Determine what kind of website you need and the specific features and functionalities you require. Also, consider the type of content develop for your website.",
    },

    {
        id:2, 
        image:"https://img.icons8.com/ios/50/code--v1.png",
        title:"Software Development",
        desc:"Software Development popular programming languages like Python, JavaScript, Java, front-end development (HTML, CSS, JavaScript), back-end development.",
    },
    {
        id:3, 
        image:"https://img.icons8.com/ios/50/development-skill.png",
        title:"AI Application",
        desc:"AI-powered NLP enables machines to understand, interpret, and generate human language. It has applications chatbots, text summarization.",
    },
    {
        id:4, 
        image:"https://img.icons8.com/ios/50/developer-mode--v1.png",
        title:"App Development",
        desc:"Development phase involves writing code to build the app. Depending on the platform like Swift(iOS),Java or Kotlin(Android) or JavaScript.",
    },
    {
        id:5, 
        image:"https://img.icons8.com/ios/50/cloud-development.png",
        title:"Cloud Application",
        desc:"Cloud application content refers to the data, files, and resources that are stored and accessed within a cloud-based application.",
    },
    {
        id:6, 
        image:"https://img.icons8.com/ios/50/customer-insight--v1.png",
        title:"Digital Marketing",
        desc:"Digital marketing content refers to any type of promotional material or distributed online to attract text, images, videos, info graphics and more.",
    },
]
export default data