import React from 'react'
import "../Feat/Feat.css"
import Card from './Card'
import data from './Feat.Api'

const Feat = () => {
  return (
    <>
     <section className='Feat top' id='Feat'>
        <div className='container'>
<div className='heading'>

    <h4>Technology</h4>
    <h1>Our Technology</h1>
</div>
<div className='content grid'>
  
  {data.map((val, index)=>{
    return<Card key={index} image={val.image} title={val.title} desc={val.desc} />
      
    })}

   
   
</div>

        </div>
        </section>  
    </>
  )
}

export default Feat
