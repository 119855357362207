import React from 'react'
import "../hero/Home.css"
import hero from '../pic/hero.png'

import "../pic/img1.png"
import {Typewriter } from "react-simple-typewriter"

const Home = () => {
  return (
    <>
      <section classname='hero' id='home'>
        
<div className='container f_flex top'>
    <div className='left top'>
        <h1>Realto Solutions</h1>
        <h2>It's Works <span>IT Solutions</span>
        
        </h2>
        <h2>
            a <span>
            <Typewriter words={['Website Development.', 'AI Application.','Software Development.']} loop cursor cursorStyle ='|'  typeSpeed={70} deleteSpeed={50} delaySpeed={1000}/>
            
             </span>
        </h2>
         
        </div>
       

        <div className='right'>
          <div className='right_img'>
            <img src={hero} alt=" "/>
          </div>
        </div>
      
    </div>

      </section>
    </>
  )
}

export default Home
