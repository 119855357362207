const TestimonialApi = [
    {
      id: 1,
      image: "./image/Rakesh.png",
      design: "Infinite Labs",
      desc: "We had the pleasure of working with Infinity Labs on a complex software development project, and they exceeded our expectations. Their team demonstrated deep technical expertise and a thorough understanding of our business needs. They delivered a high-quality solution within the specified time frame. ",
    },
    {
      id: 2,
      image: "./image/image1.jpg",
      design: "Tikona Infinet",
             desc: "We had the pleasure of working with Infinity Labs on a complex software development project, and they exceeded our expectations. Their team demonstrated deep technical expertise and a thorough understanding of our business needs. They delivered a high-quality solution within the specified time frame. ",
    },
    
    
  ]
  
  export default TestimonialApi